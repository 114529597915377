<div class="home">
  <div class="home__main-view">
    <div class="get-started">
      <div class="text">
        <h2 i18n="@@Innovative *span*IT Solutions*span* *span*& Services*span* Partner">
          Innovative <span>IT Solutions</span>
          <br />
          <span>& Services</span> Partner
        </h2>
        <p class="l normal" i18n="@@El Pixel is a trusted IT solutions provider, offering custom software development and consulting services tailored to meet the unique needs of businesses.">
          El Pixel is a trusted IT solutions provider, offering custom software
          development and consulting services tailored
          <br />
          to meet the unique needs of businesses.
        </p>
      </div>
      <div class="link">
        <button class="button-primary l" routerLink="/contact-us">
          <p class="l bold" i18n="@@Get started">Get started</p>
          <mat-icon class="small" svgIcon="arrow-up-right-bold"></mat-icon>
        </button>
      </div>
    </div>
    <div class="technology-cubes"></div>
  </div>

  <div class="home__services">
    <div class="services">
      <div class="services__text">
        <h2 i18n="@@Services">Services</h2>
        <div class="services-description">
          <p class="l normal" i18n="@@Our company specializes in developing web and cross-platform (mobile) applications.">Our company specializes in developing web and cross-platform (mobile) applications.</p>
          <p class="l normal" i18n="@@We handle projects for businesses from inception to completion.">We handle projects for businesses from inception to completion.</p>
        </div>
        <div class="action">
          <button class="button-link" routerLink="/services" i18n="@@All services *icon*">All services<mat-icon class="small" svgIcon="arrow-up-right-bold"></mat-icon></button>
        </div>
      </div>
      <div class="services__cube"></div>
    </div>
    <div class="categories">
      <div class="categories__block">
        <div class="text">
          <div class="text__image">
            <mat-icon svgIcon="software-dev"></mat-icon>
          </div>
          <h5 i18n="@@Software Development" routerLink="/services/service-development">Software Development</h5>
        </div>
        <button class="button-icon" routerLink="/services/service-development">
          <mat-icon svgIcon="arrow-up-right"></mat-icon>
        </button>
      </div>

      <div class="categories__block">
        <div class="text">
          <div class="text__image">
            <mat-icon svgIcon="mobile-app"></mat-icon>
          </div>
          <h5 i18n="@@Mobile App Development" routerLink="/services/mobile-app-development">Mobile App Development</h5>
        </div>
        <button
          class="button-icon"
          routerLink="/services/mobile-app-development"
        >
          <mat-icon svgIcon="arrow-up-right"></mat-icon>
        </button>
      </div>

      <div class="categories__block">
        <div class="text">
          <div class="text__image">
            <mat-icon svgIcon="machine-learning"></mat-icon>
          </div>
          <h5 i18n="@@Machine Learning & Artificial Intelligence integration" routerLink="/services/MA-AII">Machine Learning & Artificial Intelligence integration</h5>
        </div>
        <button class="button-icon" routerLink="/services/MA-AII">
          <mat-icon svgIcon="arrow-up-right"></mat-icon>
        </button>
      </div>

      <div class="categories__block">
        <div class="text">
          <div class="text__image">
            <mat-icon svgIcon="design"></mat-icon>
          </div>
          <h5 i18n="@@UX & UI Design" routerLink="/services/UX-UI">UX & UI Design</h5>
        </div>
        <button class="button-icon" routerLink="/services/UX-UI">
          <mat-icon svgIcon="arrow-up-right"></mat-icon>
        </button>
      </div>

      <div class="categories__block">
        <div class="text">
          <div class="text__image">
            <mat-icon svgIcon="business-analysis"></mat-icon>
          </div>
          <h5 i18n="@@Business Analysis" routerLink="/services/business-analysis">Business Analysis</h5>
        </div>
        <button class="button-icon" routerLink="/services/business-analysis">
          <mat-icon svgIcon="arrow-up-right"></mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="home__technologies">
    <div class="technology">
      <div class="technology__text">
        <h2 i18n="@@Technologies">Technologies</h2>
        <p class="l normal" i18n="@@Our company will help you go all the way from the MVP-version creation to the launch of your own product.">Our company will help you go all the way from the MVP-version creation to the launch of your own product.</p>
        <div class="action">
          <button class="button-link" routerLink="/technologies" i18n="@@All technologies *icon*">All technologies<mat-icon class="small" svgIcon="arrow-up-right-bold"></mat-icon></button>
        </div>
      </div>
      <div class="technology__cube"></div>
    </div>
    <div class="categories-technology">
      <div class="category-block">
        <div class="frontend" routerLink="/technologies/domain/frontend">
          <h5>Frontend</h5>
        </div>
        <div class="technologies">
          <div class="tech-block" routerLink="/technologies/Angular">
            <mat-icon class="big" svgIcon="angular"></mat-icon>
            <p class="l bold">Angular</p>
          </div>
          <div class="tech-block" routerLink="/technologies/Vue">
            <mat-icon class="big" svgIcon="vue"></mat-icon>
            <p class="l bold">Vue</p>
          </div>
          <div class="tech-block">
            <mat-icon class="big" svgIcon="react" routerLink="/technologies/React"></mat-icon>
            <p class="l bold">React</p>
          </div>
        </div>
      </div>
      <div class="category-block">
        <div class="backend" routerLink="/technologies/domain/backend">
          <h5>Backend</h5>
        </div>
        <div class="technologies">
          <div class="tech-block" routerLink="/technologies/Java">
            <mat-icon class="big" svgIcon="java"></mat-icon>
            <p class="l bold">Java</p>
          </div>
          <div class="tech-block" routerLink="/technologies/NET">
            <mat-icon class="big" svgIcon="net"></mat-icon>
            <p class="l bold">.NET</p>
          </div>
          <div class="tech-block" routerLink="/technologies/Python">
            <mat-icon class="big" svgIcon="python"></mat-icon>
            <p class="l bold">Python</p>
          </div>
        </div>
      </div>
      <div class="category-block">
        <div class="platforms" routerLink="/technologies/domain/platform-solutions">
          <h5 i18n="@@Platforms">Platforms</h5>
        </div>
        <div class="technologies">
          <div class="tech-block" routerLink="/technologies/AWS">
            <mat-icon class="big" svgIcon="aws"></mat-icon>
            <p class="l bold">AWS</p>
          </div>
          <div class="tech-block" routerLink="/technologies/Azure">
            <mat-icon class="big" svgIcon="azure"></mat-icon>
            <p class="l bold">Azure</p>
          </div>
          <div class="tech-block" routerLink="/technologies/GCP">
            <mat-icon class="big" svgIcon="gcp"></mat-icon>
            <p class="l bold">GCP</p>
          </div>
        </div>
      </div>
      <div class="category-block">
        <div class="machine" routerLink="/technologies/domain/ml&ai">
          <h5 i18n="@@Machine Learning & Artificial Intelligence">Machine Learning & Artificial Intelligence</h5>
        </div>
        <div class="technologies">
          <div class="tech-block" routerLink="/technologies/Vision-models">
            <p class="m bold" i18n="@@Vision models">Vision models</p>
          </div>
          <div class="tech-block" routerLink="/technologies/Diffusion-models">
            <p class="m bold" i18n="@@Diffusion models">Diffusion models</p>
          </div>
          <div class="tech-block" routerLink="/technologies/Language-models">
            <p class="m bold" i18n="@@Language models">Language models<br></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="home__statistic">
    <app-statistic [showButton]="true"></app-statistic>
  </div>
  <div class="home__projects">
    <app-projects [projects]="projects">
      <div class="all-projects">
        <h2 i18n="@@Projects">Projects</h2>
        <p class="l normal" i18n="@@Explore our diverse portfolio showcasing projects of different scales and industries, highlighting our adaptability and expertise.">Explore our diverse portfolio showcasing projects of different scales and industries, highlighting our adaptability and expertise.<br></p>
      </div>
      <div class="slider">
        <button class="button-link" routerLink="/projects" i18n="@@All projects *icon*">All projects<mat-icon class="small" svgIcon="arrow-up-right-bold"></mat-icon></button>
        <div class="action__slider">
          <button class="button-icon prev-slide">
            <mat-icon class="small rotate" svgIcon="arrow-right"></mat-icon>
          </button>
          <button class="button-icon next-slide">
            <mat-icon class="small" svgIcon="arrow-right"></mat-icon>
          </button>
        </div>
      </div>
    </app-projects>
  </div>
  <div class="home__clients">
    <app-clients-speak></app-clients-speak>
  </div>
  <div class="home__contact">
    <app-contact-us></app-contact-us>
  </div>
</div>
